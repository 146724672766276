<template>
    <div class="k-flex-col">
        <b-form-group>
            <label> <b>Identifiant</b> </label>
            <b-form-input
                v-model="mail"
                placeholder="Adresse e-mail"
                trim
                @keydown.enter.exact.prevent="login()"
            ></b-form-input>
        </b-form-group>

        <b-form-group>
            <label> <b>Mot de passe</b> </label>
            <b-form-input
                v-model="password"
                placeholder="Mot de passe"
                type="password"
                trim
                @keydown.enter.exact.prevent="login()"
            ></b-form-input>
        </b-form-group>

        <div class="k-flex-row">
            <b-button variant="kalkin-2" @click="login()">
                Se connecter
            </b-button>
            <b-link class="text-kalkin-2" to="ResetPassword">
                Mot de passe oublié
            </b-link>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            mail: "",
            password: "",
        };
    },

    methods: {
        login: async function() {
            if (
                await this.$store.dispatch("user/login", {
                    mail: this.mail,
                    password: this.password,
                })
            ) {
                this.$router.push({ name: "Home" });
            }
        },
    },
};
</script>
