<template>
    <div class="k-flex-col w-100">
        <div class="text-center mb-2">
            <h1>CONNEXION</h1>
        </div>
        <div class="k-form-fp">
            <FormLogin />
        </div>
    </div>
</template>

<script>
import FormLogin from "@/components/misc/form/FormLogin.vue";
export default {
    components: {
        FormLogin,
    },

    // Tentative de reconnexion
    created: async function() {
        if (await this.$store.dispatch("user/refreshMe")) {
            this.$router.push({ name: "Home" });
        }
    },
};
</script>
